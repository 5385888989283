import React from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import { ThemeProvider } from '@material-ui/styles';
import SEO from '../components/SEO.js';
import AppTheme from '../AppTheme.js';
import NotFound from '../components/NotFound.js';

const NotFoundPage = () => (
  <>
    <CssBaseline />
    <ThemeProvider theme={AppTheme}>
      <SEO title="404: Not found" />
      <NotFound />
    </ThemeProvider>
  </>
);

export default NotFoundPage;
